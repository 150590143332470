<template>
  <!-- <div v-else-if="vehicles.length == 0">
    <div class="col-md-12 ">
      <img alt="" :src="require('@/assets/img/404.png')" class="img-fluid" style="display:block; margin: 0 auto;">
      <h2 class="text-center">Você não fez uma pesquisa ainda!</h2>
    </div>
  </div> -->
  <div>
    <div class="card text-left error-card" v-if="error || emptyResult">
      <div class="flex empty-result-content">

      <div class="col-lg-6 col-12 error-text">
        Parece que nenhum veículo foi encontrado nesta data, <b>tente refazer a busca</b>.
      </div>

      <div class="col-lg-6 col-12">
        <not-found></not-found>
      </div>

      </div>
    </div>
    <div v-else-if="vehicles.length === 0">
      <content-loader></content-loader>
      <content-loader></content-loader>
    </div>

    <div
        class="card checkout-login"
        style="background: white"
        v-if="this.$store.state.block_ui"
        ref="login"
      >
      {{this.$store.state.companyDetails.block_results_non_logged_user}}
        <h4>Você precisa acessar sua conta para ver os resultados</h4>
        <div class="btn btn-outline">
          <login-modal :style="{color: this.$store.state.companyDetails.whitelabel.body_font_color}" type="result-list" @close="closeModal" />
        </div>
    </div>

    <div id="result-list" v-if="vehicles" :style="cssProps">
      <!-- <div class="list-header row align-items-end">
         <div class="col-md-3 rebuild-search" style="margin-left: auto;">
          <button @click="goBack" class="btn btn-block back"><b-icon-arrow-clockwise></b-icon-arrow-clockwise> REFAZER BUSCA</button>
        </div>
      </div> -->

        <div class="tab_content" v-if="transfers.length > 0 && this.$store.state.transfersGoing.isChecked">
            <b-tabs class="transfer-tab" content-class="mt-2" v-model="step" v-if="transfer_round_trip">
              <b-tab title="Selecione a ida" @click="updateTransferTab('origin')" :active="$store.state.transferType=='origin'">
                <div class="wrapper" v-if="vehicles.origin.length > 0">
                  <div class="mb-4 w-100" v-for="(vehicle, index) in vehicles.origin" v-bind:key="vehicle.id" :data-id="vehicle.id">
                    <search-card-item :index="index" :vehicle="vehicle" :vehicles="vehicles"
                    :extra="{
                      transfer: transfers[0],
                      transfer_round_trip: transfer_round_trip,
                      trip_info: tripInfo,
                      redirect: false,
                      showDetails: true,
                      daily: daily,
                      transfer_state: 'origin',
                      origin_observation: ''
                    }">
                    </search-card-item>
                  </div>
                </div>
                <b-card class="wrapper" v-else>
                  Não foram encontrados veículos nesta data
                </b-card>
              </b-tab>
              <b-tab class="transfer-tab" @click="updateTransferTab('destination')" :disabled="!$store.state.cart.cartData.items.length > 0" title="Selecione a volta" :active="$store.state.transferType=='destination'">
                <div class="wrapper" v-if="vehicles.destination.length > 0">
                  <div class="mb-4 w-100" v-for="(vehicle, index) in vehicles.destination" v-bind:key="vehicle.id" :data-id="vehicle.id">
                    <search-card-item :index="index" :vehicle="vehicle" :vehicles="vehicles"
                    :extra="{
                      transfer: transfer_round_trip,
                      transfer_round_trip: transfer_round_trip,
                      trip_info: tripInfo,
                      redirect: false,
                      showDetails: true,
                      daily: daily,
                      transfer_state: 'destination',
                      destination_observation: ''
                    }">
                    </search-card-item>
                  </div>
                </div>
                <b-card class="wrapper" v-else>
                  Não foram encontrados veículos nesta data
                </b-card>
              </b-tab>
            </b-tabs>
            <div v-else>
              <div class="wrapper">
                  <div class="mb-4 w-100" v-for="(vehicle, index) in vehicles.origin" v-bind:key="vehicle.id" :data-id="vehicle.id">
                    <search-card-item :index="index" :vehicle="vehicle" :vehicles="vehicles"
                    :extra="{
                      transfer: transfers[0],
                      transfer_round_trip: transfer_round_trip,
                      trip_info: tripInfo,
                      redirect: false,
                      showDetails: true,
                      daily: daily
                    }">
                    </search-card-item>
                  </div>
                </div>
            </div>
        </div>

         <div class="mb-4 w-100" v-else v-for="(vehicle, index) in vehicles" v-bind:key="vehicle.id" :data-id="vehicle.id">
          <search-card-item :index="index" :vehicle="vehicle" :vehicles="vehicles"
          :extra="{
            transfer: transfers[0],
            transfer_round_trip: transfer_round_trip,
            trip_info: tripInfo,
            showDetails: true,
            daily: daily,
            transfer_state: 'onlyOrigin'
          }">
          </search-card-item>
        </div>
      </div>

  </div>
</template>

<script>
// import SaveOrPrintModal from '@/components/modals/SaveOrPrintModal.vue'
// import DriverExpenses from '@/components/trip-details/DriverExpenses.vue'
import { mapGetters } from 'vuex'
import SearchCardItem from '@/components/search-results/SearchCardItem.vue'
import NotFound from '@/components/search-results/NotFound.vue'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import LoginModal from '@/components/modals/LoginModal.vue'
import ContentLoader from '@/components/search-results/ContentLoader.vue'
import utils from '@/components/mixins/utils'

export default {
  name: 'ResultList',
  title () {
    return `${this.pageTitle}`
  },
  data () {
    return {
      pageTitle: this.$store.state.companyDetails.name + ' - Resultados da busca',
      step: 1,
      vehicles: [],
      error: null,
      stop_points: [],
      show_agree_error: false,
      transfers: [],
      transfer_round_trip: null,
      emptyResult: false,
      isTrip: false,
      isDaily: false,
      isTransfer: false,
      tripInfo: [],
      daily: false
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    },
    origin () {
      return this.$store.state.origin.address
    },
    originTime () {
      return this.$store.state.origin.time
    },
    originDate () {
      let originDate = this.formatDate(this.$store.state.origin.date)

      return originDate
    },
    destination () {
      return this.$store.state.destination.address
    },
    destinationTime () {
      return this.$store.state.destination.time
    },
    destinationDate () {
      let destinationDate = this.formatDate(this.$store.state.destination.date)

      return destinationDate
    },
    getStopPoints () {
      return this.$store.state.tripDetails.stop_points
    },
    getUsageDestination () {
      return this.$store.state.tripDetails.useVehicle
    }

  },
  components: { SearchCardItem, NotFound, LottieAnimation, LoginModal, ContentLoader },
  mixins: [
    utils
  ],
  methods: {
    updateTransferTab (state) {
      this.$store.commit('SET_TRANSFER_TYPE', state)
    },
    formatDate (dateString) {
      const weekdays = ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado']
      const months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']
      let dateObj = new Date(dateString)
      dateString = weekdays[(dateObj.getDay())] + ', ' + months[(dateObj.getMonth())] + ' ' + ((dateObj.getDate() + ', ' + dateObj.getFullYear()))

      return dateString
    },
    getVehicles () {
      /* eslint-disable no-new */
      new Promise((resolve, reject) => {
        this.$http.post(process.env.API_URL + 'calculate', {
          type: this.getServiceType(this.$store.state.serviceType),
          origin: this.$store.state.origin,
          destination: this.$store.state.destination,
          is_round_trip: this.$store.state.is_round_trip,
          stop_points: this.$store.state.tripDetails.stop_points,
          vehicle_type: this.$store.state.selectedVehicleType,
          vehicle_usages: this.$store.state.tripDetails.useVehicle,
          customerId: this.$store.state.auth.userData.id
        }).then((r) => {
          if(r.status == 400) {
            this.error = r.data.message
            this.$swal({
              title: 'Ops!',
              text: r.data.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
            this.$store.commit('SET_LOADING_STATE', { value: false })
            return false;
          }
          this.vehicles = r.data.vehicles
          this.emptyResult = this.vehicles.length === 0
          this.$store.commit('cart/setGarage', r.data.garage.id)
          this.$store.commit('SET_LOADING_STATE', { value: false })
        })
          .catch((error) => {
            this.error = error.response.data.message
            this.$store.commit('SET_LOADING_STATE', { value: false })
          })
      })
    },
    setSelectedVehicle (index) {
      this.$store.commit('SET_SELECTED_VEHICLE', this.vehicles[index])

      // if (this.$store.state.agree_driver_expenses) {
      //   this.$store.commit('cart/setCartItem', {
      //     selectedVehicle: this.$store.state.selectedVehicle,
      //     type: this.$store.state.serviceType,
      //     origin: this.$store.state.origin,
      //     destination: this.$store.state.destination,
      //     tripDetails: this.$store.state.tripDetails })

      //   this.$router.push('checkout')
      //   this.show_agree_error = false
      // } else {
      //   this.show_agree_error = true
      // }
    },
    showVehicleDetails (index) {
      this.vehicles.forEach(element => {
        if (element.visible === true) {
          element.visible = false
        }
        this.vehicles[index].visible = true
      })
    },
    hideVehicleDetails (index) {
      this.vehicles[index].visible = false
    },
    goBack () {
      this.$router.push({
        name: 'white-label',
        params: {
          theme: true
        }
      })
    },
    getVehicleBySlug () {
      new Promise((resolve, reject) => {
        this.$http.post(process.env.API_URL + 'get-vehicle-by-type', {
          type: this.$store.state.selectedVehicleType,
          filters: {
            in_operation_daily: 1
          },
          daily: this.$store.state.daily
        }).then((r) => {
          this.daily = r.data.hasOwnProperty('daily') ? r.data.daily[0] : false
          let dailyQuotes = [];
          (Object.values(r.data.daily)).forEach((item) => {
            dailyQuotes.push(item.request)
          })
          this.$store.commit('SET_DAILY_OBJECT', { quotes: dailyQuotes })
          this.vehicles = r.data.daily[0].vehicles || r.data.vehicles
          this.isLoading = false
          this.emptyResult = this.vehicles.length === 0
        })
          .catch((error) => {
            this.error = error
            this.isLoading = false
          })
      })
    },
    getVehiclePrice (vehicle, type) {
      switch (type) {
        case 'transfer':
          let type = this.getVehicleType(vehicle.type) + '_price'
          let price = this.$store.state.transfers[0][type]
          return price
          break
        case 'diaria':
          return this.$store.state.daily.quotes[0].quote[this.getVehicleType(vehicle.type)]
          break
        default:
          return vehicle.total
          break
      }
    },
    saveSearch: async function (metas) {
      var searchData = {}
      var extraData = {}
      var origin = {}
      var destination = {}
      var originDate
      var destinationDate
      var isCustomTrip = false

      if (this.$store.state.tripType === 'customtrip') {
        isCustomTrip = true
        origin = this.$store.state.customRouter.days[0].routes[0].origin
        destination = this.$store.state.customRouter.days[0].routes[this.$store.state.customRouter.days[0].routes.length - 1].destination
        destinationDate = this.$store.state.customRouter.endDate
        originDate = this.$store.state.customRouter.initialDate
        extraData['trip'] = this.$store.state.customRouter
      } else {
        origin = this.$store.state.simpleTrip.origin
        destination = this.$store.state.simpleTrip.destination
        originDate = this.$store.state.simpleTrip.origin.date
        destinationDate = this.$store.state.simpleTrip.destination.date
        extraData['trip'] = this.$store.state.simpleTrip
      }

      extraData['metas'] = metas
      searchData['type'] = this.$store.state.tripType
      searchData['origin'] = origin
      searchData['destination'] = destination
      searchData['originDate'] = originDate
      searchData['destinationDate'] = destinationDate

      if (this.$store.state.auth.userData === null) {
        searchData['customer_id'] = null
        extraData['customer'] = this.$store.state.auth.tmpUserData
      } else {
        searchData['customer_id'] = this.$store.state.auth.userData.id
      }
      extraData['driverExpenses'] = metas.driverExpenses
      searchData['extra_data'] = extraData

      var $scope = this
      new Promise((resolve, reject) => {
        this.$http.post(process.env.API_URL + 'search/save', {
          customer_id: searchData.customer_id,
          district_origin: searchData.origin.['address_components'].district,
          route_origin: searchData.origin.['address_components'].route,
          city_origin: searchData.origin.['address_components'].city,
          state_origin: searchData.origin.['address_components'].state,
          date_origin: searchData.originDate,
          district_destination: searchData.destination.['address_components'].district,
          route_destination: searchData.destination.['address_components'].route,
          city_destination: searchData.destination.['address_components'].city,
          state_destination: searchData.destination.['address_components'].state,
          date_destination: searchData.destinationDate,
          type: searchData.type,
          extra_data: searchData.extra_data,
          vehicles: this.vehicles
        }).then((r) => {
          $scope.$store.commit('SAVE_SEARCH_DATA', r.data.data);
          $scope.$store.commit('MUST_SAVE_SEARCH', false)
        })
      })
    },
    simpleTripVehicles () {
      var simpleTrip = {
        tripType: this.$store.state.tripType,
        origin: this.$store.state.simpleTrip.origin,
        destination: this.$store.state.simpleTrip.destination,
        is_round_trip: this.$store.state.is_round_trip,
        stop_points: this.$store.state.simpleTrip.stop_points,
        vehicle_type: 'all',
        vehicle_usages: [],
        customerId: this.$store.state.auth.userData.id
      }
      if (this.$store.state.tripType == 'hourtrip') {
        simpleTrip.franchise = this.$store.state.simpleTrip.franchise
      }
      new Promise((resolve, reject) => {
        this.$http.post(process.env.API_URL + 'calculate', {
          simpleTrip: simpleTrip,
          customerId: this.$store.state.auth.userData.id
        }).then((r) => {
          if(r.status == 400) {
            this.error = r.data.message
            this.$swal({
              title: 'Ops!',
              text: r.data.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
            this.$store.commit('SET_LOADING_STATE', { value: false })
            return false;
          }
          this.vehicles = r.data.vehicles
          if (this.$store.state.mustSaveSearch) {
            this.saveSearch(r.data.metas)
          }
          this.emptyResult = this.vehicles.length === 0
          this.$store.commit('TOGGLE_RESET_FORM', true )
          this.$store.commit('SET_LOADING_STATE', { value: false })
          this.$store.commit('UPDATE_TRIP_METAS', r.data.metas)
          this.$store.commit('cart/setGarage', r.data.garage.id)
          this.$forceUpdate()
        })
          .catch((error) => {
            this.error = error.response.data.message
            this.$store.commit('TOGGLE_RESET_FORM', true )
            this.$store.commit('SET_LOADING_STATE', { value: false })
          })
      })
    },
    customTripVehicles () {
      var customRouterStore = this.$store.state.customRouter
      customRouterStore.initialDate = customRouterStore.initialDate
      customRouterStore.endDate = customRouterStore.endDate
      customRouterStore.customerId = this.$store.state.auth.userData.id

      var customRouter = {
        customRouter: customRouterStore,
        customerId: this.$store.state.auth.userData.id
      }
      new Promise((resolve, reject) => {
        this.$http.post(process.env.API_URL + 'calculate', customRouter).then((r) => {
          if(r.status == 400) {
            this.error = r.data.message
            this.$swal({
              title: 'Ops!',
              text: r.data.message,
              icon: 'error',
              confirmButtonText: 'Ok'
            })
            this.$store.commit('SET_LOADING_STATE', { value: false })
            return false;
          }

          this.vehicles = r.data.vehicles
          this.emptyResult = this.vehicles.length === 0
          this.$store.commit('UPDATE_TRIP_METAS', r.data.metas)
          if (this.$store.state.mustSaveSearch) {
            this.saveSearch(r.data.metas)
          }
          this.$store.commit('cart/setGarage', r.data.garage.id)
          this.$store.commit('TOGGLE_RESET_FORM', form )
          this.$store.commit('SET_LOADING_STATE', { value: false })
        })
          .catch((error) => {
            this.error = error.response.data.message
            this.$store.commit('TOGGLE_RESET_FORM', form )
            this.$store.commit('SET_LOADING_STATE', { value: false })
          })
      })
    }
  },
  mounted () {
    if (!this.user && Boolean(parseInt(this.$store.state.companyDetails.whitelabel.block_results_non_logged_user))) {
      this.$store.commit('SET_BLOCK_UI', true)
    } else {
      this.$store.commit('SET_BLOCK_UI', false)
    }

    if (this.vehicles.length === 0) {
      if (this.$store.state.tripType === 'customtrip') {
        this.customTripVehicles()
      } else {
        this.simpleTripVehicles()
      }
    } else {
      this.vehicles = []
    }
  }
}
</script>

<style lang="scss">

.forgot-pass {
    font-size: 12px!important;
    text-transform: none!important;
    margin-bottom: 10px!important;
    font-weight: 400!important;
    color: #727272!important;
}

.register-account {
    margin: 15px 0;
    display: flex;
    justify-content: center;
    font-size: 16px;
}

.container-login100-form-btn {
    display: flex!important;
    justify-content: space-between;
    gap: 10px!important;;
}

.container-login100-form-btn button{
    flex: 1;
}

.container-login100-form-btn .back-btn {
    background: transparent!important;
    border-color: #adadad!important;
    color: #adadad!important;;
}

.checkout-login {

.btn {
  margin-bottom: 20px;
}

h4 {
  text-align: center;
  padding: 20px;
  color: #565656;
}

a {
  width: fit-content;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 0 auto;
  border: 1px solid var(--default-color);
  color: var(--default-color);
  margin-bottom: 20px;

  &:hover {
    background-color: var(--default-color);
    color: white!important;
  }
}
}

.error-card {
  .flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .error-text {
    font-size: 24px;
    color: var(--default-color)!important;

    b {
      display: inline-block;
      color: var(--default-color)!important;
    }
  }
}

#result-list {
  margin-top: 30px;
  .transfer-tab {
    ul {
        display: flex;
        justify-content: flex-start!important;
        margin-bottom: -10px;
      li {

        margin-right: 10px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        overflow: hidden;

        &:hover {
          background-color: white;
          a {
            background-color: white;
            color: var(--default-color);
          }
        }

        a {

          border: none;
          background: #efefef;
          padding: 10px;
          border: 1px solid #eae8e8;
          border-top-left-radius: 5px;
          overflow: hidden;
          color: #adadad;
          border-top-right-radius: 5px;

          &.active {
            background-color: white;
            color: var(--default-color);
          }
        }

      }
    }
  }
}
@media print {
  .no-print{
    display:none;
  }

  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media (max-width: 980px) {
  .empty-result-content {
    flex-direction: column!important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .rebuild-search {
    padding-left: 35px!important;
  }
}

</style>
