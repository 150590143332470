<template>
  <div class="row row-trip-info w-100">
    <div class="col-12 mt-4">
      <h4 class="trip-info-title w-100 d-flex justify-content-between">
        <div class="title">
          Viagem {{ tripTypeName }} 
          <span class="title-badge" v-if="extraTripDetails">{{ extraTripDetails }}</span>
        </div>
      </h4>

      <div class="usage-item align-items-start xl-row mb-4 w-100" v-if="this.route == 'checkout' && this.vehicle.type !== 'customtrip' || this.route != 'checkout' && this.$store.state.tripType !== 'customtrip'">
        <div :class="[true ? 'col-origin m-xl-0 pr-5' : '', destination ? 'col-md-5' : 'col-md-10']">
          <h4 class="trip-info-title">Origem</h4>
          <div class="trip-info">
            <b-icon-geo-alt></b-icon-geo-alt>
            {{ origin }}
          </div>
          <div class="trip-info">
            <b-icon-calendar3></b-icon-calendar3>
            {{ originDate | date_result_list }}
          </div>
          <div class="trip-info">
            <b-icon-clock></b-icon-clock>
            {{ originTime }}
          </div>
        </div>

        <div class="col-destination col-md-5 p-xl-0" v-if="destination">
          <h4 class="trip-info-title">Destino</h4>
          <div class="trip-info">
            <b-icon-geo-alt></b-icon-geo-alt>
            {{ destination }}
          </div>
          <div class="trip-info" v-if="destinationDate">
            <b-icon-calendar3></b-icon-calendar3>
            {{ destinationDate | date_result_list }}
          </div>
          <div class="trip-info" v-if="this.$store.state.simpleTrip.is_round_trip || this.$store.state.tripType == 'customtrip' || this.vehicle.type === 'roundtrip'" >
            <b-icon-clock></b-icon-clock>
            {{ destinationTime }}
          </div>
          <div class="trip-info" v-if="franchise">
            <b-icon-clock></b-icon-clock>
            Uso do veículo por {{ franchise }} horas
          </div>
        </div>

        <div class="col-lg-2 col-12 align-items-lg-end align-items-start px-lg-2 p-0">
          <div class="price-wrapper">
            <div class="price">
              <span class="value">{{ this.vehicle.total | currency }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="w-100">
        <div class="usage-item align-items-center row mb-4 w-100">
          <div :class="[true ? 'col-origin m-xl-0 pr-5 col-lg-9 col-12' : '']">
            <h4 class="trip-info-title">1º dia - {{ originDate | date_result_list }}</h4>
            <div class="trip-info">
              <b-icon-circle></b-icon-circle>
              {{ origin }}
            </div>
            <div class="trip-info">
              <b-icon-geo-alt></b-icon-geo-alt>
              {{ destination }}
            </div>
            <div class="trip-info">
              <b-icon-clock></b-icon-clock>
              {{ originTime }}
            </div>
          </div>

          <div class="col-lg-3 col-12 align-items-center px-lg-2 p-0">
            <div class="price-wrapper">
              <div class="price">
                <div class="btn-outline-primary btn" @click.prevent="displayItinerary()">Ver roteiro completo</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div :class="[true ? 'single-itinerary col-12' : '', showItinerary ? 'active' : 'inactive']" v-if="mustShowItinerary" @click.prevent="closeItinerary()">
      <div class="content">
        <h3>Resumo do roteiro</h3>
        <itinerary-summary :key="randomKey" :data="customRouter"></itinerary-summary>
      </div>
    </div>
  </div>
</template>

<script>
import utils from '@/components/mixins/utils'
import ItinerarySummary from '../../modals/CustomTripRoute/ItinerarySummary.vue'

export default {
  name: 'TripAddress',
  props: ['vehicle', 'extra', 'route'],
  components: { ItinerarySummary },
  mixins: [ utils ],
  data () {
    return {
      roundTrip: this.isRoundTrip(),
      quotes: this.$store.state.daily.quotes,
      busIcon: require('../../../assets/icon/bus.svg'),
      dailysQuote: '',
      extraTripDetails: this.extraData(),
      showItinerary: false,
      randomKey: Math.round(Math.random() * 1000),
      mustShowItinerary: this.$store.state.customRouter.initialDate != '' || ( this.vehicle.customRouter && this.vehicle.customRouter.initialDate != '' ),
      dataRefer: this.vehicle,
      customRouter: [],
    }
  },
  computed: {
    franchise() {
      var franchise = 0;
      if(this.route == 'checkout') {
        franchise = this.vehicle.franchise;
      }else{
        franchise = this.$store.state.simpleTrip.franchise;
      }

      return franchise > 0 ? franchise : false;
    },
    tripTypeName(){

      if( this.route != 'checkout' ){
        if(this.roundTrip) { return 'ida e volta' }
        switch (this.$store.state.tripType) {
          case 'customtrip':
              return 'com roteiro personalizado' 
            break;
          case 'hourtrip':
              return 'por hora' 
            break;
          case 'roundtrip':
              return 'ida e volta' 
            break;
          default:
            return 'somente ida';
            break;
        }
      }else{
        if(this.vehicle.selectedVehicle.usingTwoTrips) {
          return 'por trecho'
        }
        switch (this.vehicle.type) {
          case 'customtrip':
              return 'com roteiro personalizado' 
            break;
          case 'hourtrip':
              return 'por hora' 
            break;
          case 'roundtrip':
              return 'ida e volta' 
            break;
          default:
            return 'somente ida';
            break;
        }
      }
    },
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    },
    showTripTypeActions () {
      return this.$store.state.tripType == 'customtrip' && this.route != 'checkout' || this.vehicle.type == 'customtrip' ? true : false;
    },
    origin () {
      return this.getTripInformation('origin', 'address');
    },
    originTime () {
      var time = this.getTripInformation('origin', 'time');
      return time ? time : '00:00'
    },
    originDate () {
      return this.getTripInformation('origin', 'date');
    },
    destination () {
      return this.getTripInformation('destination', 'address');
    },
    destinationTime () {
      return this.getTripInformation('destination', 'time');
    },
    destinationDate () {
      var date = this.getTripInformation('destination', 'date');
      return date != '' ? date : false;
    },
    getStopPoints () {
      return this.$store.state.simpleTrip.stop_points
    },
    getUsageDestination () {
      return this.$store.state.tripDetails.useVehicle
    }
  },
  methods: {
    closeItinerary() {
      this.showItinerary = false;
    },
    extraData() {
      if(!this.$store.state.transfersGoing.isChecked || this.$store.state.serviceType != 'transfer'){
        return false;
      }else{
        return this.extra.transfer_state === 'origin' ? 'Veículo da ida' : 'Veículo da volta'
      }
    },
    isRoundTrip() {

      if(this.route == 'checkout') {
        return this.vehicle.isRoundTrip ? true : false;
      }else{
        return this.$store.state.simpleTrip.is_round_trip ? true : false;
      }
     
    },
    displayItinerary () {
      this.randomKey = Math.round(Math.random() * 1000);
      this.customRouter = this.route == 'checkout' && this.vehicle.type == 'customtrip' ? this.vehicle.customRouter.days : this.$store.state.tripType == 'customtrip' ? this.$store.state.customRouter.days : false;
      this.showItinerary = true;
    },
    getTripInformation(source, field) {
      let output = '';
      if(this.route == 'checkout'){
        if(this.vehicle.type == 'customtrip'){
          let routeIndex = source == 'destination' ? this.vehicle.customRouter.days[0].routes.length - 1 : 0;
          if(field == 'date'){
            output = this.vehicle.customRouter.initialDate;
            output = this.moment(output).format('YYYY/MM/DD');
          }else{
            output = this.vehicle.customRouter.days[0].routes[routeIndex][source][field];
          }
        }
        else {
          output = this.vehicle[source][field]
        }
      }else{
        if(this.$store.state.tripType == 'customtrip'){
          let routeIndex = source == 'destination' ? this.$store.state.customRouter.days[0].routes.length - 1 : 0;
          if(field == 'date'){
            output = this.$store.state.customRouter.days[0].routes[routeIndex][field];
            output = this.moment(output,'DD/MM/YYYY').format('YYYY/MM/DD');
          }else if(field == 'time') {
            output = this.$store.state.customRouter.days[0].routes[routeIndex][field];
          }else{
            output = this.$store.state.customRouter.days[0].routes[routeIndex][source][field];
          }
        } else {
          output = this.$store.state.simpleTrip[source][field]
        }
      }

      return output;
    },
    getType (type) {
      switch (type) {
        case 'diaria':
          let quantity =  this.$store.state.daily.quotes.length;
          let result = quantity > 1 ? 'das Diárias' : 'da diária';
          return 'Detalhes ' + result
        case 'transfer':
          return 'Transfer'
        default:
          return 'Viagem'
      }
    },
    formatTitleVehicle (title) {
      // return title ? title.split(' ').slice(1).join(' ') : ''
      return title
    },
    getVehicleType (type) {
      switch (type) {
        case 'onibus':
          return 'bus'
        case 'van':
          return 'van'
        case 'micro-onibus':
          return 'micro'
        case 'carro' :
          return 'car'
        default:
          return 'bus'
      }
    },
  },
  mounted() {

  },
  beforeMount () {
  }
}
</script>

<style lang="scss" scoped>

  .row-trip-info {
    .btn-small {
      padding: 2px 30px;
      border-radius: 4px;
    }
    .btn-outline-primary {
      background-color: white;
      color: var(--default-color)!important;
      border-color: var(--default-color)!important;
      &:hover {
        color: white!important;
        background-color: var(--default-color)!important;
        cursor: pointer;
      }
    }
  }

  .single-itinerary {
    
    display: none;
    .content {
      background: white;
      padding: 30px;
      border-radius: 10px;
      right: -10px;
      position: relative;
      padding-right: 40px;
      border: 1px solid;
      border-color: var(--default-color);
      width: 30vw;
      max-height: 80vh;
      overflow: auto;

      &::-webkit-scrollbar {
          width: 16px!important;
          height: 6px!important;
      }
      &::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.184);
      }
      &::-webkit-scrollbar-track {
          background: transparent;
      }


      h3 {
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 20px;
        color: var(--default-color);
      }

      @media(max-width: 980px) {
      width: 100vw;
      }
    }

    &.active {
      position: fixed;
      top: 0;
      right: 0;
      height: 100vh;
      z-index: 999999999;
      background: #00000038;
      padding: 0;
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media(max-width: 980px) {
        left: 0;
      }
    }
  }

  #search-results  {
    #result-list  {
      .result-item  {

        .row-actions {
          .col-actions {
            .book-button {
                border-color: var(--default-color);
                color: white!important;
                background-color: var(--default-color);
                border-radius: 4px;
            }
          }
        }

        .first-row {
          padding: 30px 40px;
          @media (max-width: 1024px) {
            padding: 15px;
          }
        }

        .row-stop-points {
          .col-md-5 {
           
          }
        }
        .col-info {
          .configurations-wrapper {
            padding-top: 5px;

            span {
              background: transparent;
              color: var(--default-color);
              margin-right: 5px;
              padding: 0 5px;
              margin-bottom: 5px;
              display: flex;
              border-color: var(--default-color);
              border: 1px solid;
              border-radius: 5px;
            }
            p {
              text-align: left;
              margin-bottom: 0;
              font-size: 14px;
              color: #000;
              text-transform: capitalize;
              display: flex;
              flex-wrap: wrap;
            }
            .configurations {
              padding-top: 10px;
            }
          }
        }
        .configurations {
          span {

          }
        }
        .col-price  {
          .pricePerSeat {
            span {
              font-size: 14px;
              font-weight: 500;
              text-align: center;
            }
          }
          .more-info  {
            a {
              background-color: var(--default-color);
              border-color: var(--default-color);
              color: white;
            }
          }
        }
      }
    }
  }

</style>
